import { useState } from "react"
import { generateWalletPass } from "../services/api"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import mockup from '../assets/MockupBase-English.webp'
import { Snackbar, Alert } from "@mui/material"

const GenerateWallet = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    website: "",
    foregroundColor: "#ffffff",
    backgroundColor: "#621668",
    labelColor: "#ffffff"
  })

  const [logo, setLogo] = useState(null)
  const [background, setBackground] = useState(null)
  const [imageToCrop, setImageToCrop] = useState(null)
  const [crop, setCrop] = useState({ aspect: 1 / 1, width: 50 }) // Default: Square crop
  const [cropType, setCropType] = useState(null) // 'logo' or 'background'
  const [imageRef, setImageRef] = useState(null)


  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState("success")

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setImageToCrop(reader.result)
        setCropType(e.target.name) // Determine which image is being cropped
        setCrop(e.target.name === "logo" ? { aspect: 1 / 1, width: 50 } : { aspect: 3 / 1, width: 50 })
      }
      reader.readAsDataURL(file)
    }
  }

  const getCroppedImage = (imageSrc, crop, type, imgElement) => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = imageSrc
      image.crossOrigin = "anonymous"
  
      image.onload = () => {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")
  
        if (!ctx || !imgElement) {
          reject(new Error("Canvas context is not available or image element missing"))
          return
        }

         // Detect image format from src
        const fileType = imageSrc.includes("image/jpeg") ? "image/jpeg" : "image/png"
  
        // Scale factors to adjust for CSS resizing
        const scaleX = image.naturalWidth / imgElement.width
        const scaleY = image.naturalHeight / imgElement.height
  
        // Convert crop values from the displayed size to the natural size
        const pixelCropX = crop.x * scaleX
        const pixelCropY = crop.y * scaleY
        const pixelCropWidth = crop.width * scaleX
        const pixelCropHeight = crop.height * scaleY
  
        // Set canvas size to match the cropped area
        canvas.width = pixelCropWidth
        canvas.height = pixelCropHeight
  
        // Draw the cropped area onto the canvas
        ctx.drawImage(
          image,
          pixelCropX, pixelCropY, pixelCropWidth, pixelCropHeight, // Source crop area
          0, 0, canvas.width, canvas.height // Destination canvas
        )
  
        // Convert to a file object
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error("Canvas to Blob failed"))
            return
          }
          resolve(new File([blob], `${type}.${fileType.split("/")[1]}`, { type: fileType }))
        }, fileType)
      }
    })
  }
  
  
  
  const handleCropConfirm = async () => {
    if (!imageToCrop || !crop.width || !crop.height || !imageRef) {
      console.error("Invalid crop selection or image reference missing.")
      return
    }
  
    try {
      const croppedImage = await getCroppedImage(imageToCrop, crop, cropType, imageRef)
  
      if (cropType === "logo") {
        setLogo(croppedImage)
      } else {
        setBackground(croppedImage)
      }
  
      console.log("Cropped Image Processed:", croppedImage)
  
      setImageToCrop(null) // Close crop window
      setCropType(null)
    } catch (error) {
      console.error("Error processing cropped image:", error)
    }
  }
  
     

  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
  
    Object.keys(formData).forEach((key) => data.append(key, formData[key]))
    if (logo) data.append("logo", logo)
    if (background) data.append("background", background)
  
    try {
      const passFile = await generateWalletPass(data) // Get the file blob
  
      // Create a downloadable link
      const downloadUrl = window.URL.createObjectURL(passFile)
      const link = document.createElement("a")
      link.href = downloadUrl
      link.download = "business_card.pkpass"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
  
      setSnackbarMessage("Wallet pass downloaded successfully!")
      setSnackbarSeverity("success")
      setSnackbarOpen(true)
    } catch (error) {
      setSnackbarMessage("Error generating wallet pass")
      setSnackbarSeverity("error")
      setSnackbarOpen(true)
      console.error("Download Error:", error)
    }
  }
  

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <div className="homeContainer">
        <div className="formContainer">
        <div className="formOne">
            <h1>iOS Wallet Business Card Generator</h1>
            <h2>Generate your digital business card for your iPhone wallet for free 😍</h2>
            <form onSubmit={handleSubmit}>
            <h4>Contact Information</h4>
            <label>Name*</label>
            <input className="textInput" type="text" name="name" placeholder="Name" onChange={handleChange} required />
            <label>Email*</label>
            <input className="textInput" type="email" name="email" placeholder="Email" onChange={handleChange} required />
            <label>Phone*</label>
            <input className="textInput" type="text" name="phone" placeholder="Phone" onChange={handleChange} required />
            <label>Company (Optional)</label>
            <input className="textInput" type="text" name="company" placeholder="Company" onChange={handleChange} />
            <label>Website (Optional)</label>
            <input className="textInput" type="text" name="website" placeholder="Website" onChange={handleChange} />

            <h4>Colors</h4>
            <div className="colorPickers">
                <label>Background:</label>
                <input type="color" name="backgroundColor" value={formData.backgroundColor} onChange={handleChange} />
                
                <label>Labels:</label>
                <input type="color" name="labelColor" value={formData.labelColor} onChange={handleChange} />

                <label>Text:</label>
                <input type="color" name="foregroundColor" value={formData.foregroundColor} onChange={handleChange} />
            </div>

            <h4>Images</h4>
            <label>Icon (Square)</label>
            <input className="fileInput" type="file" name="logo" accept="image/png, image/jpeg" onChange={handleFileChange} />

            <label>Main Image (Rectangle)</label>
            <input className="fileInput" type="file" name="background" accept="image/png, image/jpeg" onChange={handleFileChange} />

            <div className="consentCheckbox">
              <input type="checkbox" required />
              <p>I consent to my information being stored and used for marketing, analytics, and targeted advertising purposes, in accordance with the <a href="https://webshau.com/business-card-privacy-policy/" target="_blank">Privacy Policy</a>.</p>
            </div>
              
            <button type="submit">Generate</button>

            <h6>Need an app or web project? Developed by: <a href="https://webshau.com" target="blank">Webshau!</a></h6>
            </form>
        </div>
        <div className="formTwo">
            <img src={mockup} alt="Mockup of iPhone wallet business card for free" />
        </div>
        </div>

        {/* Image Cropper Modal */}
        {imageToCrop && (
        <div className="cropContainer">
            <h3>Crop {cropType === "logo" ? "Icon (Square)" : "Main Image (Rectangle)"}</h3>
            <div className="cropButtons">
                <button onClick={handleCropConfirm}>Confirm Crop</button>
                <button onClick={() => setImageToCrop(null)}>Cancel</button> {/* Closes modal */}
            </div>

            {/* CHECKPOINT */}
            <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onComplete={(c) => setCrop(c)}
            aspect={cropType === "logo" ? 1 / 1 : 3 / 1} // Lock aspect ratio
            keepSelection
            >
            <img
                id="crop-image"
                src={imageToCrop}
                alt="To Crop"
                ref={(img) => setImageRef(img)} // Store reference to image element
                onLoad={(e) => {
                const imgElement = e.currentTarget // Get the actual rendered image

                const renderedWidth = imgElement.width // Get visible width
                const renderedHeight = imgElement.height // Get visible height
                const isSquare = cropType === "logo"

                // Set crop to 60% of the displayed image width
                const cropWidth = renderedWidth * 0.6
                const cropHeight = isSquare ? cropWidth : cropWidth / 2

                setCrop({
                    unit: "px",
                    width: cropWidth,
                    height: cropHeight,
                    x: (renderedWidth - cropWidth) / 2, // Center horizontally
                    y: (renderedHeight - cropHeight) / 2, // Center vertically
                    aspect: isSquare ? 1 / 1 : 3 / 1
                })
                }}
                style={{ maxWidth: "100%", display: "block" }}
            />
            </ReactCrop>
            <div className="cropButtons">
                <button onClick={handleCropConfirm}>Confirm Crop</button>
                <button onClick={() => setImageToCrop(null)}>Cancel</button> {/* Closes modal */}
            </div>
        </div>
        )}

        {/* MUI Snackbar for notifications */}
        <Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
            </Alert>
        </Snackbar>
    </div>
  )
}

export default GenerateWallet
