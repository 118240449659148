import { useState } from "react"
import { generateWalletPass } from "../services/api"
import mockup from '../assets/MockupBase-English.webp'

const GenerateWallet = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    website: "",
    foregroundColor: "#ffffff",
    backgroundColor: "#621668",
    labelColor: "#ffffff"
  })

  const [logo, setLogo] = useState(null)
  const [background, setBackground] = useState(null)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleFileChange = (e) => {
    if (e.target.name === "logo") setLogo(e.target.files[0])
    if (e.target.name === "background") setBackground(e.target.files[0])
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()

    Object.keys(formData).forEach((key) => data.append(key, formData[key]))
    if (logo) data.append("logo", logo)
    if (background) data.append("background", background)

    console.log("Form Data Entries:")
    for (let pair of data.entries()) {
        console.log(pair[0], pair[1])
    }

    try {
      const response = await generateWalletPass(data)
      alert("Wallet pass generated successfully!")
      console.log(response)
    } catch (error) {
      alert("Error generating wallet pass")
    }
  }

  return (
    <div className="formContainer">
      <div className="formOne">
        <h1>iOS Wallet Business Card Generator</h1>
        <h2>Generate your digital business card for your iphone wallet for free 😍</h2>
        <form onSubmit={handleSubmit}>
          <h4>Contact information</h4>
          <label>Name</label>
          <input className="textInput" type="text" name="name" placeholder="Name" onChange={handleChange} required />
          <label>Email</label>
          <input className="textInput" type="email" name="email" placeholder="Email" onChange={handleChange} required />
          <label>Phone</label>
          <input className="textInput" type="text" name="phone" placeholder="Phone" onChange={handleChange} required />
          <label>Company (Optional)</label>
          <input className="textInput" type="text" name="company" placeholder="Company" onChange={handleChange} />
          <label>Website (Optional)</label>
          <input className="textInput" type="text" name="website" placeholder="Website" onChange={handleChange} />

          <h4>Colors</h4>
          <div className="colorPickers">
            <label>Background:</label>
            <input type="color" name="backgroundColor" value={formData.backgroundColor} onChange={handleChange} />
            
            <label>Label:</label>
            <input type="color" name="labelColor" value={formData.labelColor} onChange={handleChange} />

            <label>Text:</label>
            <input type="color" name="foregroundColor" value={formData.foregroundColor} onChange={handleChange} />
          </div>

          <h4>Images</h4>
          <label>Icon</label>
          <input type="file" name="logo" accept="image/png" onChange={handleFileChange} />

          <label>Main Image</label>
          <input type="file" name="background" accept="image/png" onChange={handleFileChange} />

          <button type="submit">Generate</button>

          <h6>Developed by: <a href="https://webshau.com" target="blank" >Webshau!</a></h6>
        </form>
      </div>
      <div className="formTwo">
        <img src={mockup} alt="Mockup of iphone wallet business card for free" />
      </div>
      
    </div>
  )
}

export default GenerateWallet
