import { useEffect } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import Home from './pages/Home'
import GenerateWallet from './pages/GenerateWallet'
import ReactGA from 'react-ga4'

const AnalyticsRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search })
  }, [location])

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/generate" element={<GenerateWallet />} />
    </Routes>
  )
}

function App() {
  return (
    <div className="App"> 
      <BrowserRouter>
        <div className="pages">
          <AnalyticsRoutes />
        </div>   
      </BrowserRouter>
    </div>
  )
}

export default App
