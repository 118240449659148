import axios from "axios"

export const generateWalletPass = async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_GENERATE_WALLET_API, data, {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob"
      })
      return response.data
    } catch (error) {
      console.error("Error generating wallet pass:", error)
      throw error
    }
}